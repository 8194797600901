<template>
    <div class="page-warp">
        <div class="row con-img">
            <van-swipe indicator-color="white" v-if="Alldata.pictureUrl">
                <van-swipe-item v-for="(picItem,picInx) in Alldata.pictureUrl.split(',')" :key="picInx">
                    <div class="swiper-imgs">
                        <img v-if="picItem" :src="picItem"/>
                    </div>
                </van-swipe-item>
            </van-swipe>
            <div class="swiper-imgs" v-else>
                <img src="../../assets/images/product.png" />
            </div>
        </div>
        <div class="row service-com">
            <div class="s-c-item">
                <span class="n">{{Alldata.inNumber}}个</span>
                <span class="t">企业入驻名额</span>
            </div>
            <div class="s-c-item">
                <span class="n">{{Alldata.registeredNumber}}个</span>
                <span class="t">已入驻</span>
            </div>
            <div class="s-c-item">
                <span class="n">{{Alldata.remainingNumber}}个</span>
                <span class="t">剩余入驻名额</span>
            </div>
        </div>
        <div class="row service-total mt-10">
            <div class="s-t-item">
                <span class="n">{{Alldata.completeTaxAmountW}}万</span>
                <span class="t">园区总纳税额</span>
            </div>
            <div class="s-t-item">
                <span class="n">{{Alldata.taxableAmountW}}万</span>
                <span class="t">入驻纳税额</span>
            </div>
            <div class="s-t-item">
                <span class="n">{{Alldata.remainingTaxAmountW}}万</span>
                <span class="t">剩余纳税额</span>
            </div>
        </div>
        <div class="row page-model-title mt-20">园区介绍</div>
        <div class="content-warp" v-html="Alldata.profile"></div>

        <!--<div class="row rz-btn mt-30">
            <div>我要入驻</div>
        </div>-->
    </div>
</template>
<script>
import { findById } from "@/api/order";
export default {
    name:'ServiceDetail',
    data() {
        return {
            orderId:'',
            Alldata:{},
        };
    },
    created() {
        this.orderId = this.$route.query.id;
        this.findAll();
    },
    methods: {
        //   通过id查询订单信息
        async findAll() {
            let res = await findById(this.orderId);
            this.Alldata = res.data;
        },
    },
};
</script>
<style lang="less" scope>
    .con-img {
        position: relative; z-index: 1;
        height: 300px;
        .swiper-imgs {
            position: relative; z-index: 1;
            height: 300px; 
        }
        img {
            position: absolute; left: 50%; top:50%;
            min-width: 100%; min-height:100%;
        }
    }
    .service-com,
    .service-total {
        display: flex; position: relative; z-index: 5;
        box-sizing: border-box; padding-top:5px;
        span {
            display: block;
            width: 100%; height: auto;
            text-align: center;
        }
        .s-c-item {
            .n { font-size: 14px; line-height: 22px;}
            .t { font-size: 10px; line-height: 16px;}
        }
        .s-t-item {
            .n { font-size:16px; line-height: 26px; color: #000;}
            .t { font-size: 13px; line-height: 16px; color: rgba(8, 8, 8, 0.45)}
        }
    }
    .service-com {
        background: rgba(33, 90, 204, 0.7);
        margin-top:-50px; height: 50px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        color: #fff;
    }
    .page-model-title {
        text-align: left; line-height: 40px; font-size: 16px; color: #080808;
        box-sizing: border-box; padding:0px 10px;
    }
    .content-warp {
        box-sizing: border-box;
        padding:0px 10px;
        img { width: 100%;}
    }
    .rz-btn {
        &>div {
            width:90%; height: 46px; line-height: 46px; color:#fff; font-size:16px;
            background:#215ACC;
            border: 1px solid #215ACC;
            border-radius: 30px;
            text-align: center;
        }
    }
</style>