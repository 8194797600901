/*
 * @Description: 订单信息查询
 * @Author: lucl
 * @Date: 2021-06-30 10:33:58
 * @LastEditors: lucl
 * @LastEditTime: 2021-07-02 10:48:15
 */

import { get } from "@/utils/request"

export function pageQuery(data){
    return get('/system/area/listForMobile',data)
}
export function findById(data){
    return get('/system/area/'+data)
}
// 接受订单
export function takeOrder(data){
    return get('/order/takeOrder',data)
}
// 拒绝订单
export function rejectOrder(data){
    return get('/order/rejectOrder',data)
}